<template>
    <div class="btn relative" style="cursor: pointer;">
        <div v-if="!without" class="btn-bg" :class="{'big': big}"></div>
        <div class="btn-bg btn-bg-2"></div> 
        <div
            @mouseenter="color = '#ea704a'"
            @mouseleave="color = '#272727'"
            class="btn__content relative d-flex justify-center align-center"
            :style="text !== '' ? 'gap: 8px; padding: 16px 24px;' : 'padding: 16px;'"
            :class="{'second': second, 'without': without}"
        >
            <UiIcon v-if="iconPos === 'left' && !noIcon" :name="icon" width="20" height="20" :color="color"/>
            <p class="btn__text" v-if="text !== ''">{{ text }}</p>
            <UiIcon v-if="iconPos === 'right' && !noIcon" :name="icon" width="20" height="20" :color="color"/>
        </div>        
    </div>
</template>

<script setup>
import {ref} from "vue";
import UiIcon from './UiIcon.vue';

const color = ref("#272727");
const props = defineProps({
    icon: String,
    iconPos: {
        type: String,
        default: "left"
    },
    noIcon: Boolean,
    text: {
        type: String,
        default: ''
    },
    second: Boolean,
    big: Boolean,
    without: Boolean
})
</script>

<style lang="scss" scoped>
.btn {
    overflow: hidden;
    width: min-content;
    flex: 0 0 auto;
    border-radius: 16px;
    z-index: 2;
    &:hover {
        & .btn-bg-2 {
            border: 1px solid #EB671D;
        }
        .btn__content {
            color: #ea704a;
        }
        & > svg g path, & svg path {
            stroke: #ea704a !important;
        }
    } 
    &-bg {
        overflow: hidden;
        position: absolute;
        width: 98%;
        height: 98%;
        top: 2px;
        left: 2px;
        background-image: url('../../assets/images/p_back.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: -1;
        transition: border .25s ease;
        &.big {
            background-image: url('../../assets/images/btn-back.png');
            background-size: contain;
        }
        &-2 {
            background: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(26px); 
            z-index: 0;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 16px;
            overflow: hidden;
            border: 1px solid rgba(0,0,0, 0.1);
        }
    }
    &__content {
        z-index: 1;
        flex-wrap: nowrap;
        white-space: nowrap;
        color: #272727;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 100%;
        overflow: hidden;
        // border-radius: 16px;
        transition: color .25s ease;
        &.without {
            // padding: 24px !important;
            border-radius: 20px;
        }
        & svg {
            transition: stroke .25s ease;
        }
        &.second {
            padding: 24px !important;
        }
    }
}
</style>