import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: function () {
      return import('../views/AboutView.vue')
    }
  },
  {
    path: '/offers',
    name: 'offers',
    component: function () {
      return import('../views/OffersView.vue')
    }
  },
  {
    path: '/monitorro',
    name: 'monitorro',
    component: function () {
      return import('../views/MonitorroView.vue')
    }
  },
  {
    path: '/seo',
    name: 'seo',
    component: function () {
      return import('../views/SearchEngineView.vue')
    }
  },
  {
    path: '/competitor',
    name: 'competitor',
    component: function () {
      return import('../views/CompetitorInsightView.vue')
    }
  },
  {
    path: '/web-solutions',
    name: 'web-solutions',
    component: function () {
      return import('../views/WebSolutionsView.vue')
    }
  },
  {
    path: '/pay-per-click',
    name: 'pay-per-click',
    component: function () {
      return import('../views/PPCView.vue')
    }
  },
  {
    path: '/remarketing',
    name: 'remarketing',
    component: function () {
      return import('../views/RemarketingView.vue')
    }
  },
  {
    path: '/compelling',
    name: 'compelling',
    component: function () {
      return import('../views/CompellingView.vue')
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: function () {
      return import('../views/PrivacyView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to, from) => {
  document.body.classList.remove('no-scroll');
  window.scrollTo(0, 0);
})

export default router
