<template>
    <!-- <div
        class="icon-wrapper"
        :style="`width: ${width}px; height: ${height}px`"
        @mouseout="hovered = false"
        @mouseover="hovered = true"
    > -->
        <!-- <img
            :src="require(`@/assets/icons/${name}.svg`)" 
            alt="icon" 
            :class="fit === 'cover' ? 'cover' : 'contain', hovered ? 'color: #ea704a' : 'color: #272727'"
        />
        <span v-html="svgContent"></span> -->
        <svg
            :width="width"
            :height="height"
            xmlns="http://www.w3.org/2000/svg"
            :fill="notStroke ? color : 'none'"
            v-html="svgContent"
            :stroke="!notStroke ? color : 'initial'"
            :viewBox="`0 0 ${width} ${height}`"
            class="icon-wrapper"
        ></svg>
    <!-- </div> -->
</template>

<script setup>
import {ref, onMounted} from "vue";
const props = defineProps({
    name: String,
    width: String,
    height: String,
    color: {
        type: String,
        default: "#272727"
    },
    fit: {
        type: String,
        default: "cover"
    },
    notStroke: Boolean
})

const svgContent = ref('');

onMounted(async () => {
    try {
        const filesContext = require.context('@/assets/icons/', false, /\.svg$/);
        const matchingFiles = filesContext.keys().filter((key) => key.includes(props.name));
        if (matchingFiles.length > 0) {
            const response = await import(`!!raw-loader!@/assets/icons/${matchingFiles[0].replace('./', '')}`);
            svgContent.value = response.default;
        } else {
            console.error(`No matching SVG file found for ${props.name}`);
        }
    } catch (error) {
        console.error(`Error loading SVG for ${props.name}:`, error);
    }
});
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: middle;
  svg path, g path {
    stroke: none;
  }
}
// .icon-wrapper {
//     width: 100%;
//     height: 100%;
//     display: block;
//     & img {
//         width: 100%;
//         height: 100%;
//         display: block;
//         &.cover {
//             object-fit: cover;
//         }
//         &.contain {
//             object-fit: contain;
//         }
//     }
// }
</style>