<template>
    <footer>
        <div class="container">
            <div class="footer__top d-flex align-start justify-between">
                <div class="logo-block d-flex justify-between">
                    <router-link to="/"><UiIcon name="logo" width="48" height="48"/></router-link>
                    <div class="d-flex column">
                        <p class="color-white text-xs" @click="scroll('mission')" style="cursor:pointer">Mission</p>
                        <p class="color-white text-xs" @click="scroll('services')" style="cursor:pointer">Services</p>
                        <p class="color-white text-xs" @click="scroll('solution')" style="cursor:pointer">Solution</p>
                    </div>
                </div>
                <div>
                    <p class="text-xs color-white opacity-half">Office 4, 10/F., <br/>Kwan Chart Tower, <br/>No. 6 Tonnochy Road,<br/> Wanchai, Hong Kong</p>
                </div>
            </div>
            <div class="bottom d-flex align-center justify-between">
                <div class="d-flex bottom-left">
                    <p class="color-white opacity-half">© 2024 Firefly Ad Technologies</p>
                    <RouterLink to="/privacy">Privacy Policy</RouterLink>
                </div>
                <div class="d-flex bottom-right">
                    <a class="text-xs" target="_blank" href="https://www.linkedin.com/company/firefly-ad-technologies/">Linkedin</a>
                    <a class="text-xs" href="mailto:info@fireflyad.tech">info@fireflyad.tech</a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>
import UiIcon from './Ui/UiIcon.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const scroll = (id) => {
    router.push("/").then(() => {
        const scrollDiv = document.getElementById(id).offsetTop - 150;
        window.scrollTo({ left: 0, top: scrollDiv, behavior: "smooth" });
    })
}
</script>

<style lang="scss" scoped>
footer {
    background: #000;
    color: #fff;
    padding: 64px 0;
    & .footer__top {
        & > .d-flex .text-xs{
            transition: all .25s ease;
            &:hover {
                color: #ea704a;
            }   
        }
    }
    & .logo-block {
        gap: 80px;
        & .d-flex {
            gap: 16px;
        }
    }
    & .bottom {
        margin-top: 80px;
        &-left {
            gap: 24px;
        }
        &-right {
            gap: 40px;
        }
    }
    & a {
        text-decoration: none;
        color: #fff;
        &:visited, &:active, &:focus {
            color: #fff !important;
        }
        &:hover {
            cursor: pointer;
        }
    }
}

@media (max-width: 900px) {
    footer {
        & .bottom {
            flex-direction: column-reverse;
            align-items: flex-start;
            gap: 50px;
            &-right {
                flex-direction: row-reverse;
            }
        }
    }
}

@media (max-width: 700px) {
    footer {
        padding: 40px 0;
        & .footer__top {
            flex-direction: column;
            gap: 40px;
        }
        & .logo-block {
            gap: 30px;
            flex-direction: column;
            .d-flex {
                flex-direction: row;
            }
        }
        & .bottom {
            margin-top: 40px;
            &-left {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
}

@media (max-width: 425px){
    footer {
        padding: 30px 0;
        & .logo-block {
            gap: 20px;
        }
        & .footer__top {
            gap: 30px;
        }
        & .bottom {
            margin-top: 30px;
            gap: 40px;
            &-right, &-left {
                gap: 16px;
            }
        }
    }
}
</style>
