<template>
    <div class="ui-modal">
        <div class="ui-modal__wrap" @click="$emit('close'), close = true"></div>
        <div class="ui-modal__content modal">
            <div v-if="!isCalled && !isSent" class="modal-item">
                <div class="bg"></div>
                <div class="modal__head relative d-flex align-center justify-center">
                    <p class="text-l color-white">Message Us</p>
                    <svg style="cursor: pointer" @click="$emit('close'), close = true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M6 18L18 6M6 6L18 18" stroke="white" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <form id="form">
                    <div class="modal__body d-flex column"> 
                        <div class="d-flex modal__body_div">
                        <div class="d-flex align-center input" :class="{'error': isError && data.name.toString().trim().length == 0}">
                            <input type="text" placeholder="Name" @input="handleInput" v-model="data.name" required maxlength="100" aria-required="true"/>
                            <svg v-if="isError && data.name.toString().trim().length == 0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM10 5C10.1989 5 10.3897 5.07902 10.5303 5.21967C10.671 5.36032 10.75 5.55109 10.75 5.75V10.25C10.75 10.4489 10.671 10.6397 10.5303 10.7803C10.3897 10.921 10.1989 11 10 11C9.80109 11 9.61032 10.921 9.46967 10.7803C9.32902 10.6397 9.25 10.4489 9.25 10.25V5.75C9.25 5.55109 9.32902 5.36032 9.46967 5.21967C9.61032 5.07902 9.80109 5 10 5ZM10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15Z" fill="#FF3D00"/>
                            </svg>
                        </div>
                        <div class="d-flex align-center input" :class="{'error': isError && !emailRegex.test(data.email)}">
                            <input type="email" placeholder="Email" v-model="data.email" required maxlength="250"/>
                            <svg v-if="isError && !emailRegex.test(data.email)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM10 5C10.1989 5 10.3897 5.07902 10.5303 5.21967C10.671 5.36032 10.75 5.55109 10.75 5.75V10.25C10.75 10.4489 10.671 10.6397 10.5303 10.7803C10.3897 10.921 10.1989 11 10 11C9.80109 11 9.61032 10.921 9.46967 10.7803C9.32902 10.6397 9.25 10.4489 9.25 10.25V5.75C9.25 5.55109 9.32902 5.36032 9.46967 5.21967C9.61032 5.07902 9.80109 5 10 5ZM10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15Z" fill="#FF3D00"/>
                            </svg>
                        </div>
                    </div>
                    <div class="input"><input type="text" placeholder="Subject" v-model="data.subject"/></div>
                    <div class="input"><textarea placeholder="Message" v-model="data.message"></textarea></div>
                    <div class="d-flex column checkboxes">
                        <label class="custom-checkbox d-flex" for="ch-1" @click="isChecked = !isChecked" :key="isChecked">
                            <input type="checkbox" id="ch-1" :checked="isChecked" required name="terms"/>
                            <span class="checkmark"></span>
                            <span >I have read and accept the <router-link to="/privacy">Privacy Policy</router-link>.</span>
                        </label>
                        <label class="custom-checkbox d-flex" for="ch-2">
                            <input type="checkbox" id="ch-2"/>
                            <span class="checkmark"></span>
                            <span >I want to receive notifications about the latest products, events and cutting-edge ideas.</span>
                        </label>
                    </div>
                </div>
                <div class="button relative" @click="send"><UiButton text="Continue" no-icon big without></UiButton></div>
            </form>
            </div>
            <div v-else class="relative modal-item">
                <div class="bg second"></div>
                <div class="desktop-bg">
                    <UiIcon name="modal-logo" height="321" width="321" without color="white"></UiIcon>
                </div>
                <template v-if="(!isCalled && isSent && isSuccess) || (isCalled && isParentSuccess)">
                    <div class="d-flex column answer justify-center align-center color-white">
                        <svg class="svg-close" style="cursor: pointer" @click="$emit('close'), close = true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M6 18L18 6M6 6L18 18" stroke="white" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p class="answer__title">Success!</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="123" height="122" viewBox="0 0 123 122" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M27.6102 10.2804C37.6416 3.57759 49.4354 0 61.5 0C77.6725 0.0186283 93.1773 6.45138 104.613 17.8871C116.049 29.3227 122.481 44.8275 122.5 61C122.5 73.0647 118.922 84.8584 112.22 94.8898C105.517 104.921 95.99 112.74 84.8437 117.357C73.6974 121.974 61.4323 123.182 49.5995 120.828C37.7667 118.474 26.8975 112.664 18.3665 104.133C9.83551 95.6025 4.02582 84.7333 1.67213 72.9005C-0.681573 61.0677 0.52643 48.8026 5.14337 37.6563C9.76032 26.51 17.5788 16.9831 27.6102 10.2804ZM44.4059 87.8024L25.3599 69.0332L31.9291 62.3514L47.6483 77.836L91.0193 34.2538L97.6613 40.8583L51.0315 87.7696L47.7255 91.122L44.4059 87.8024Z" fill="white"/>
                        </svg>
                        <p class="answer__text">Your application has been accepted. You will be contacted shortly for further details.</p>
                        <div class="button relative"><UiButton text="Continue" no-icon without big @click="$emit('close'), close = true"></UiButton></div>
                    </div>
                </template>
                <template v-if="(!isCalled && isSent && !isSuccess) || (isCalled && !isParentSuccess)">
                    <div class="d-flex column">
                        <p>Oops!</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="137" height="136" viewBox="0 0 137 136" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M68.5 7C56.4354 7 44.6416 10.5776 34.6102 17.2804C24.5788 23.9831 16.7603 33.51 12.1434 44.6563C7.52643 55.8026 6.31843 68.0677 8.67213 79.9005C11.0258 91.7333 16.8355 102.602 25.3665 111.133C33.8975 119.664 44.7667 125.474 56.5995 127.828C68.4323 130.182 80.6974 128.974 91.8437 124.357C102.99 119.74 112.517 111.921 119.22 101.89C125.922 91.8584 129.5 80.0646 129.5 68C129.481 51.8275 123.049 36.3227 111.613 24.8871C100.177 13.4514 84.6725 7.01863 68.5 7ZM43.7305 49.2109L49.7094 43.232L68.4994 62.0221L87.2891 43.2324L93.268 49.2113L74.4783 68.001L93.27 86.7926L87.2911 92.7715L68.4994 73.9799L49.7074 92.7719L43.7285 86.793L62.5205 68.001L43.7305 49.2109Z" fill="white"/>
                        </svg>
                        <p>Something went wrong. Please try again!</p>
                        <div class="button relative"><UiButton text="Continue" no-icon without big @click="$emit('close'), close = true"></UiButton></div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, watch} from 'vue';
import UiButton from './UiButton.vue';
import UiIcon from "./UiIcon.vue";

const isSent = ref(false);
const isSuccess = ref(false);
const close = ref(false);

const props = defineProps({
    isCalled: Boolean,
    isParentSuccess: Boolean
})

const data = ref({
    name: "",
    email:"",
    subject: "",
    message: ""
})

watch(close.value, () => {
    if(close.value) resetForm();
})

const isError = ref(false);
const isChecked = ref(false);
const emailRegex = ref(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

const validate = () => {
    let error = false;
    Object.entries(data.value).map(([key, value]) => {
        if(key == 'name' && value.toString().trim().length == 0) error = true;
        if(key === 'email' && !emailRegex.value.test(value)) error = true;
    });
    return error;
}

const handleInput = (event) => {
    data.value.name = event.target.value;
    if(isError.value) validate();
}

const resetForm = () => {
    data.value = {
        name: "",
        email: "",
        subject: "",
        message: ""
    }
}

const fetchData = (formdata) => {
    const request = {
        method: 'POST',
        body: formdata,
        mode: 'no-cors',
    }

    fetch('https://license.darmius.kz/mailsend', request)
    .then(response => {
        isSent.value = true;
        isSuccess.value = true;
        resetForm();
    })
    .catch(e => {
        isSent.value = true;
        isSuccess.value = false;
        resetForm();
    })
}

const send = () => {
    let form = document.getElementById('form');
    isError.value = validate();
    form.reportValidity();
    if(!isError.value && isChecked.value) {
        const formdata = new FormData();
        formdata.append('id', 'fireflyad-technologies');
        formdata.append('name', data.value.name);
        formdata.append('email', data.value.email);
        formdata.append('emailto', "info@fireflyad.tech");
        formdata.append('message', data.value.message);
        formdata.append('subject', data.value.subject);

        fetchData(formdata)
    }
}

</script>

<style lang="scss" scoped>
.ui-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    overflow: hidden;
    .desktop-bg {
        position: absolute;
        top: 30px;
        left: 0;
    }
    .bg {
        left: -83%;
        width: 222%;
        height: 114%;
        top:0;
    }
    &__wrap {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background: rgba(31, 31, 31, 0.88);
    }
    &__content {
        position: fixed;
        width: 100%;
        max-width: 645px;
        // height: 100%;
        min-height: 570px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;
        & .button, .btn {
            width: 100%;
            z-index: 3;
        }
        & .modal-item {
            padding: 30px 65px;
            border-radius: 32px;
            overflow: hidden;
            position: relative;
            z-index: 1;
        }
        & .input {
            border: 2px solid #fff;
            outline: none;
            border-radius: 16px;
            background: #fff;
            backdrop-filter: blur(13px);
            overflow: hidden;
            width: 100%;
            padding-right: 16px;
            transition: border .3s ease;
            &.error, &.error:hover {
                border: 2px solid #FF3D00;
            }
            & input, textarea {
                border: none;
                outline: none;
                width: 100%;
                height: 100%;
                padding: 16px 0 16px 16px;
                font-size: 15px;
            }
            &:hover {
                border: 2px solid rgba(39, 39, 39, 0.40);
            }
        }
        & textarea {
            resize: none;
            min-height: 98px;
            overflow: hidden;
        }
    }
}

.answer {
    gap: 20px;
    position: relative;
    & .svg-close {
        position: absolute;
        right: 0;
        top: 0;
        path {
            transition: all .3s ease;
        }
        &:hover path {
            fill: #ea704a;
            stroke: #ea704a;
        }
    }
    &__title {
        font-size: 35px;
        font-weight: 500;
        margin-bottom: 10px;
        line-height: normal;
    }
    &__text {
        font-size: 18px;
        font-weight: 400;
        line-height: 26px; 
        width: 60%;
        text-align: center;
    }
}

.modal {
    &__head {
        & svg {
            position: absolute;
            right: -30px;
            path {
                transition: all .3s ease;
            }
            &:hover path {
                fill: #ea704a;
                stroke: #ea704a;
            }
        }
    }
    &__body {
        gap: 16px;
        margin: 30px 0;
        &_div {
            gap: 16px;
        }
        & .checkboxes {
            margin-top: 13px;
            color: #fff;
            a, a:visited, a:active, a:focus {
                color: #fff;
            }
        }
        & input, textarea {
            width: 100%;
        }
    }
    input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        height: 20px;
        width: 10px;
    }
}

.custom-checkbox {
  font-size: 14px;
  line-height: 22px;
  & span:nth-child(3) {
    padding-left: 12px;
  }  
  &:hover {
    cursor: pointer;
    .checkmark{
        border: 2px solid rgba(39, 39, 39, 0.40);
    }
  }
  & + .custom-checkbox {
    margin-top: 12px;
  }
  & span > span {
    text-decoration: underline;
  }
  .checkmark {
    display: inline-block;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    border-radius: 6px;
    border: 2px solid #fff;
    background: #fff;
    backdrop-filter: blur(13px);
    cursor: pointer;
    transition: border .3s ease;
  }
  input[type="checkbox"]:checked + .checkmark {
    background-size: 75% 75%;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("@/assets/icons/check.svg");
  }
  input[type="checkbox"]:checked + .checkmark::before {
    display: block;
  }
  a {
    text-decoration: underline;
  }
}

@media (max-width: 767px) {
    .modal {
        .text-l {
            font-size: 28px;
            line-height: normal;
        }
        &__head svg {
            right: 0;
        }
        &__body {
            margin: 3.2vh 0 3vh;
            gap: 1.74vh;
            &_div {
                flex-direction: column;
                gap: 1.74vh;
            }
            .checkboxes {
                margin-top: 1vh;
            }
            .custom-checkbox {
                line-height: 2.4vh;
            }
        }
    }
    .ui-modal {
        & .bg {
            height: 160%;   
        }
        &__content {
            width: 90%;
            overflow: hidden;
            .modal-item {
                padding: 24px;
            } 
        }
        .desktop-bg {
            display: none !important;
        }
    }
    .answer {
        gap: 0;
        &__title {
            margin-bottom: 22px;
        }
        &__text {
            margin: 10px 0 30px;
        }
    }
}
</style>