<template>
  <div class="home">
    <div class="first-screen relative color-27">
      <div class="bg"></div>
      <Header></Header>
      <div class="container">
        <div class="first-screen__title d-flex column">
          <p class="text-xxl color-white">Fuel Your Success with Firefly Ad Technologies</p>
          <p class="text-s color-white">We are committed to fueling your success through innovative marketing and IT solutions. With our strategic approach, advanced tools, and unwavering support, we empower businesses to accelerate their growth and achieve remarkable results.</p>
          <UiButton icon="btn" iconPos="right" text="Get in touch" @click="openModal"></UiButton>
        </div>
      </div>
      <div class="first-screen__block main-over-block" id="mission">
        <p class="text-xl">Embark on a New Journey with <br/> <span class="color-primary">Firefly Ad Technologies</span></p>
        <div class="first-screen__block__content d-flex justify-between align-end">
          <div class="d-flex first-screen__block__content-block">
            <div class="first-screen__block__item d-flex column">
              <p class="text-xs">At Firefly Ad Technologies, we are the catalyst for taking your company to new heights.</p>
              <p class="text-xs">As a leading digital marketing agency, we specialize in delivering high-quality advertising and promotions across a diverse range of platforms, leveraging the power of information technology.</p>
              <p class="text-xs">In today's rapidly evolving world, staying ahead of marketing trends is crucial for any business, and we are here to guide you every step of the way.</p>
            </div>
            <div class="first-screen__block__item d-flex column">
              <p class="text-xs"><span class="font-700">FIREFLY</span> excels at resolving common challenges such as analyzing vast amounts of information and adapting swiftly to external conditions.</p>
              <p class="text-xs">Our team of seasoned professionals is dedicated to ensuring the effective promotion of your business online, optimizing advertising costs, and driving increased sales.</p>
              <p class="text-s font-700"><span class="color-primary">With our expertise, your brand will shine brightly amidst the digital landscape</span>, empowering your company's growth and success.</p>
            </div>
          </div>
          <router-link to="/about"><UiButton icon="btn" iconPos="right" text="Learn More"></UiButton></router-link>
        </div>
      </div>
      <div class="text-scroll d-flex align-center">
        <div v-for="i in 4" :key="i" class="inner d-flex align-center">
          <span>Web Solutions</span>
          <UiIcon name="star" width="24" height="24" />
          <span>Pay-Per-Click</span>
          <UiIcon name="star" width="24" height="24" />
          <span>SEO</span>
          <UiIcon name="star" width="24" height="24" />
          <span>Competitor Analysis</span>
          <UiIcon name="star" width="24" height="24" />
        </div>
      </div>
    </div>
    
    <div class="second-screen">
      <div class="second-screen__block">
        <p class="text-xl line-normal color-27">Empowering Your Digital Success</p>
        <div class="items d-flex" id="services">
          <div class="items__item d-flex column justify-between" v-for="(item, index) in items" :key="item" :class="index==0 || index==3 || index==4 ? 'orange' : 'yellow'">
            <p class="text-s font-700">{{ item.title }}</p>
            <p class="text-xs">{{ item.text }}</p>
            <RouterLink :to="item.path"><UiButton icon="btn"></UiButton></RouterLink>
          </div>
        </div>
      </div>
    </div>

    <div class="third-screen">
      <div class="container">
        <p class="text-xl color-27" id="solution">Cutting-edge Software Solutions for Digital Success</p>
        <div class="third-screen__block d-flex">
          <div class="third-screen__block-item d-flex column">
            <UiIcon name="icon-1" width="72" height="72" notStroke fill="#fff"></UiIcon>
            <p class="text-s font-700">Offers Software</p>
            <div class="d-flex column block">
              <p class="text-xs">is a powerful marketing tool designed to help companies establish strategic partnerships, expand their target audience, and attract new users.</p>
              <p class="text-xs">It enables you to monitor and track statistics on new and engaged users, evaluate departmental effectiveness, and make informed decisions to improve your marketing processes—all in one efficient platform.</p>
              <p class="text-xs">With Offers Software, you can quickly retrieve essential data, customize user notifications, and experience seamless system performance.</p>
            </div>
            <RouterLink to="/offers"><UiButton icon="btn" iconPos="right" text="Learn More" /></RouterLink>
          </div>
          <div class="third-screen__block-item">
            <UiIcon name="icon-2" width="72" height="72" notStroke fill="#fff"></UiIcon>
            <p class="text-s font-700">Monitorro Software</p>
            <div class="d-flex column block">
              <p class="text-xs">is a game-changing solution for automating monitoring and loading times of domains like websites and landing pages. It empowers you to assess link performance and gain valuable insights into how they appear to end users.</p>
              <p class="text-xs">Whether you're a marketing corporation or a private company, Monitorro Software helps you proactively identify and address potential issues, ensuring optimal results and avoiding undesirable outcomes.</p>
              <p class="text-xs">Discover the ease and efficiency of our software solutions at Firefly Ad Technologies, revolutionizing your marketing efforts.</p>
            </div>
            <RouterLink to="/monitorro"><UiButton icon="btn" iconPos="right" text="Learn More" /></RouterLink>
          </div>
        </div>
      </div>
    </div>

    <UiForm 
      title="Ready to take your digital advertising to new heights?"
      subtitle="Get in touch with our team by filling out the form below."
    />

    <Footer></Footer>
  </div>
  <UiModal v-if="isModal" @close="closeModal"></UiModal>
</template>

<script setup>
import {ref} from "vue";
import Header from '@/components/Header.vue';
import UiButton from '@/components/Ui/UiButton.vue';
import UiIcon from '@/components/Ui/UiIcon.vue';
import UiForm from "@/components/Ui/UiForm.vue";
import UiModal from "@/components/Ui/UiModal.vue";
import Footer from "@/components/Footer.vue";

const isModal = ref(false);

const items = ref([
  {
    title: "Search Engine Mastery (SEO):",
    text: "Unlock the power of SEO to drive your company's success by obtaining valuable insights through traffic analysis, lead generation, and increased sales. Our SEO strategies will elevate your keyword rankings and boost website traffic.",
    path: "/seo"
  },
  {
    title: "Competitor Insight Analytics:",
    text: "Gain a competitive edge with in-depth analysis of your main competitors and market dynamics. Armed with this valuable information, we'll guide your SEO efforts, enhance performance, and expand your customer base.",
    path: "/competitor"
  },
  {
    title: "Web Solutions and Development:",
    text: "Our expert team at Firefly crafts custom websites and optimized mobile apps tailored to achieve your unique goals and enhance your marketing efforts. We prioritize key site elements while eliminating unnecessary components, such as rotating sliders and lengthy form fields.",
    path: "/web-solutions"
  },
  {
    title: "Pay-Per-Click Powerhouse (PPC):",
    text: "Harness the power of PPC advertising to optimize your digital marketing campaigns, reduce cost-per-lead, and increase your return on investment. With our expertise, your website will soar to the top of search results, providing quick data on potential customers",
    path: "/pay-per-click"

  },
  {
    title: "Remarketing Magic:",
    text: "Tap into the potential of effective advertising through various remarketing platforms. As the visitor count on your site grows, your retargeting list expands, increasing the visibility of your products or services.",
    path: "/remarketing"
  },
  {
    title: "Compelling Content Creation:",
    text: "Captivate your audience and drive engagement with high-quality content. Our experienced team stays up to date with the latest news, trends, and market standards to develop content that stands out. Expect attention-grabbing headlines, strategically placed keywords, well-structured messages, and visually appealing images.",
    path: "compelling"
  },
])

const openModal = () => {
    isModal.value = true;
    document.body.classList.add('no-scroll')
}

const closeModal = () => {
    isModal.value = false;
    document.body.classList.remove('no-scroll');
}
</script>

<style lang="scss" scoped>
@keyframes animate_text {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}
.first-screen {
  width: 100%;
  overflow: hidden;
  & .bg {
    height: 63%;
  }
  &__title {
    margin: 73px 0 64px;
    .text-s {
      margin: 24px 0 40px;
      width: 78%;
    }
  }
  &__block {
    &__content {
      margin-top: 64px;
      gap: 32px;
      &-block {
        gap: 56px;
      }
    }
    &__item {
      gap: 16px;
      &:nth-child(1) {
        flex: 1 0 40%;
      }
      &:nth-child(2) {
        & .text-xs {
          width: 75%;
        }
      }
    }
  }
}
.text-scroll {
  background: #000;
  padding: 16px 0;
  white-space: nowrap;
  overflow: hidden;
  width: max-content;
  gap: 48px;
  & .inner {
    gap: 48px;
    color: #fff;
    font-size: 24px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    animation: animate_text 20s linear infinite; 
  }
}

.second-screen {
  margin: 128px 0;
  &__block {
    border-radius: 40px;
    background: #ECEFF1;
    max-width: calc(1640px - 240px);
    padding: 96px;
    margin: 0 auto;
  }
}

.items {
  flex-wrap: wrap;
  gap: 36px;
  margin-top: 64px;
  &__item {
    width: calc(50% - 18px);
    padding: 48px 16px 16px 48px;
    color: #fff;
    border-radius: 24px;
    & .text-xs {
      margin: 8px 0 28px;
      width: 87%;
    }
    & .btn {
      margin-left: auto;
    }
    &.orange {
      background: #EA704A;
    }
    &.yellow {
      background: #F90;
    }
  }
  a {
    width: min-content;
    margin-left: auto;
  }
}

.third-screen {
  margin-bottom: 128px;
  & .text-xl {
    width: 60%;
    text-align: center;
    margin: 0 auto 64px;
  }
  &__block {
    gap: 36px;
    height: 100%;
    &-item {
      padding: 48px;
      border-radius: 40px;
      width: calc(50% - 18px);
      flex: 0 1 100%;
      & .text-s {
        margin: 40px 0 24px;
      }
      & a {
        display: block;
        margin-top: auto;
      }
      & .btn {
        margin-left: auto;
      }
      & .block {
        gap: 16px;
        margin-bottom: 48px;
      }
      &:nth-child(1){
        background: #EA704A;
        color: #fff;
      }
      &:nth-child(2) {
        background: #D2B5E0;
        color: #272727;
      }
    }
  }
}

@media (max-width: 1220px){
  .first-screen {
    &__title {
      margin: 64px 0 64px;
    }
    .text-s {
        font-size: 22px;
        line-height: 30px;
    }
    &__block {
      &__content {
        flex-direction: column;
      }
      &__item {
        &:nth-child(2) {
          & .text-xs {
            width: 100%;
          }
        }
      }
    }
  }
  .second-screen {
    &__block {
      padding: 85px;
    }
  }
  .third-screen {
    & .text-xl {
      text-align: left;
      margin: 0 0 64px;
      width: 100%;
    }
  }
}

@media (max-width: 900px) {
  .items {
    flex-wrap: wrap;
    margin-top: 40px;
    &__item {
      flex: 1 0 100%;
    }
  }
  .second-screen{
    margin: 80px 0;
  }
  .third-screen {
    &__block {
      flex-direction: column;
      &-item {
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px){
  .first-screen {
    &__title {
      margin: 48px 0 48px;
      .text-s {
        font-size: 18px;
        line-height: 28px;
        width: 100%;
      }
    }
    &__block {
      &__content {
        margin-top: 40px;
        &-block {
          flex-direction: column;
          gap: 16px;
        }
      }
    }
  }
  .second-screen {
    margin: 64px 0 60px;
    &__block {
      border-radius: 30px;
      padding: 40px;
    }
  }
  .items {
    margin-top: 30px;
    gap: 20px;
  }
  .third-screen {
    margin-bottom: 80px;
    & .text-xl {
      margin: 0 0 30px;
    }
    &__block-item {
      padding: 30px;
      & .text-s {
        margin: 30px 0 10px;
      }
      & .block {
        gap: 10px;
        margin-bottom: 30px;
      }
      & .btn {
        width: 100%;
      }
    }
  }
}

@media (max-width: 425px){
  .first-screen {
    &__title {
      text-align: center;
      margin: 30px 0 20px;
      justify-content: center;
      & .text-s {
        margin: 20px 0 30px;
        font-size: 16px;
        line-height: 22.4px;
      }
    }
    &__block {
      &__content {
        flex-direction: column;
        margin-top: 30px;
        &-block {
          gap: 10px;
        }
        & a {
          width: 100%;
        }
      }
      &__item {
        gap: 10px;
      }
    }
    & .btn {
      width: 100%;
    }
  }
  .second-screen {
    &__block {
      padding: 30px 20px;
    }
  }
  .items {
    &__item {
      padding: 30px;
    }
    & .text-xs {
      margin: 10px 0 18px;
      width: 100%;
    }
  }
  .third-screen {
    margin-bottom: 60px;
    &__block {
      gap: 20px;
    }
    .container {
      padding: 0 20px;
    }
  }
}
</style>
