<template>
    <header>
        <div class="container relative">
            <div class="header-wrap d-flex align-center justify-between relative" :class="{'fixed': isFixed}">
                <router-link to="/"><UiIcon name="logo" width="48" height="48" color="#fff"><icon-write /></UiIcon></router-link>
                <div class="menu" @click="openModal(false)"><div class="menu__item" v-for="i in 4"></div></div>

                <div class="nav d-flex align-center justify-between">
                    <div class="nav__menu d-flex align-center justify-between">
                        <div class="nav__menu__item d-flex align-center justify-between">
                            <RouterLink to="/about"><p class="color-white">Mission</p></RouterLink>
                        </div>
                        <div class="nav__menu__item relative services d-flex align-center">
                            <p class="color-white">Services</p>
                            <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.93301 4.75C3.74056 5.08333 3.25944 5.08333 3.06699 4.75L0.901924 1C0.709474 0.666666 0.950036 0.25 1.33494 0.25L5.66506 0.25C6.04996 0.25 6.29053 0.666667 6.09808 1L3.93301 4.75Z" fill="white"/>
                            </svg>
                            <div class="nav__menu__popover popover pop1">
                                <div class="popover__content color-white">
                                    <div class="relative popover__item" v-for="i in services" :key="i" :class="{'route': route.name === i.path}">
                                        <RouterLink :to="`/${i.path}`">
                                            <div class="popover__item_bg"></div>
                                            <div class="popover__item_bg popover__item_bg-blur"></div>
                                            <p class="text-xs font-500">{{ i.text }}</p>
                                        </RouterLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="nav__menu__item relative solution d-flex align-center">
                            <p class="color-white">Solution</p>
                            <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.93301 4.75C3.74056 5.08333 3.25944 5.08333 3.06699 4.75L0.901924 1C0.709474 0.666666 0.950036 0.25 1.33494 0.25L5.66506 0.25C6.04996 0.25 6.29053 0.666667 6.09808 1L3.93301 4.75Z" fill="white"/>
                            </svg>
                            <div class="nav__menu__popover popover pop2">
                                <div class="popover__content color-white">
                                    <div class="relative popover__item" v-for="i in solutions" :key="i" :class="{'route': route.name === i.path}">
                                        <RouterLink :to="`/${i.path}`">
                                            <div class="popover__item_bg"></div>
                                            <div class="popover__item_bg popover__item_bg-blur"></div>
                                            <p class="text-xs font-500">{{ i.text }}</p>
                                        </RouterLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="nav__btn d-flex align-center justify-between" @click="openModal(true)">
                        <UiButton icon="chat" text="Contact Us"></UiButton>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="h-menu" v-if="isMenu">
            <div class="h-menu__blur"></div>
            <div class="h-menu__bg"></div>
            <div class="h-menu__content color-white">
                <div class="h-menu__top d-flex align-center justify-between">
                    <router-link to="/"><UiIcon name="logo" width="48" height="48" color="#fff"/></router-link>
                    <svg style="cursor: pointer" @click="closeModal(false)" xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6245 11L11 13.6245L29.3755 32L32 29.3755L13.6245 11Z" fill="white"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M29.3755 11L11 29.3755L13.6245 32L32 13.6245L29.3755 11Z" fill="white"/>
                    </svg>
                </div>
                <div class="d-flex column h-menu__body">
                    <RouterLink class="h-title" :class="{'active': route.name === 'home'}" to="/"><p>Home</p></RouterLink>
                    <RouterLink class="h-title" :class="{'active': route.name === 'about'}" to="/about"><p>Mission</p></RouterLink>
                    <div class="h-title">
                        <div class="d-flex align-center" @click="isMobService = !isMobService, isMobSolution = false">
                            <p class="h-title__main" style="margin-right:10px;">Services</p>
                            <svg style="transition: transform .2s ease" :style="isMobService ? 'transform: rotate(-90deg)' : ''" xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none">
                                <path d="M7 11L0.937822 0.5L13.0622 0.500001L7 11Z" fill="white"/>
                            </svg>
                        </div>
                        <div class="hidden-block" :style="isMobService && !isMobSolution ? 'display: block' : ''">
                            <div v-for="i in services" :key="i" class="hidden-el" :class="{'active': route.name === i.path}">
                                <RouterLink :to="`/${i.path}`" class="d-flex align-center">
                                    <UiIcon :name="route.name === i.path ? `${i.icon}_act` : i.icon" width="26" height="26" without not-stroke></UiIcon>
                                    <p class="small-item">{{ i.text2 ? i.text2 : i.text }}</p>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    <div class="h-title">
                        <div class="d-flex align-center" @click="isMobSolution = !isMobSolution, isMobService = false">
                            <p class="h-title__main" style="margin-right:10px;">Solutions</p>
                            <svg style="transition: transform .2s ease" :style="isMobSolution ? 'transform: rotate(-90deg)' : ''" xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none">
                                <path d="M7 11L0.937822 0.5L13.0622 0.500001L7 11Z" fill="white"/>
                            </svg>
                        </div>
                        <div class="hidden-block" :style="isMobSolution && !isMobService ? 'display: block' : ''">
                            <div v-for="i in solutions" :key="i" class="hidden-el" :class="{'active': route.name === i.path}">
                                <RouterLink :to="`/${i.path}`" class="d-flex align-center">
                                    <UiIcon :name="route.name === i.path ? `${i.icon}_act` : i.icon" width="26" height="26" not-stroke></UiIcon>
                                    <p class="small-item">{{ i.text2 }}</p>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    <div class="content-btm">
                        <UiButton text="Contact Us" icon="chat" @click="openModal(true)" without/>
                        <div class="d-flex justify-between align-items bottom">
                            <RouterLink to="/privacy"><p>Privacy Policy</p></RouterLink>
                            <a target="_blank" href="https://www.linkedin.com/company/firefly-ad-technologies/"><p>Linkedin</p></a>
                            <a href="mailto:info@fireflyad.tech"><p>info@fireflyad.tech</p></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <UiModal v-if="isModal" @close="closeModal(true)"></UiModal>
</template>

<script setup>
import {ref, onMounted, onUnmounted} from "vue";
import { useRoute } from 'vue-router';
import UiIcon from "@/components/Ui/UiIcon.vue";
import UiButton from "./Ui/UiButton.vue";
import UiModal from "./Ui/UiModal.vue";

const services = ref([
    { text: 'Search Engine Mastery (SEO)', text2: "Search Engine Mastery", path: "seo", icon: "service-1"},
    { text: 'Competitor Insight Analytics', path: "competitor", icon: "service-2"},
    { text: 'Web Solutions and Development', path: "web-solutions", icon: "service-3"}, 
    { text: 'Pay-Per-Click Powerhouse', path: "pay-per-click", icon: "service-4"},
    { text: 'Remarketing Magic', path: "remarketing", icon: "service-5"}, 
    { text: 'Compelling Content Creation', path: "compelling", icon: "service-6"}
])
const solutions = ref([
    {text: 'Partnership Optimization with Offers Software', text2: "Offers Software", path: "offers", icon: "icon-1"}, 
    {text: 'Monitorro Software: Elevate Your Marketing Performance', text2:"Monitorro Software", path: "monitorro", icon: "icon-2"}
]);

const route = useRoute();

const isModal = ref(false);
const isMenu = ref(false);
const isMobService = ref(true);
const isMobSolution = ref(false);

const isFixed = ref(false);
const headerHeight = 0;

const openModal = (modal) => {
    if(modal) isModal.value = true;
    else isMenu.value = true;
    document.body.classList.add('no-scroll')
}

const closeModal = (modal) => {
    if(modal) isModal.value = false;
    else isMenu.value = false;
    if(!isMenu.value && !isModal.value) document.body.classList.remove('no-scroll');
}

const handleScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    isFixed.value = scrollTop > (headerHeight + 10);
}

onMounted(() => {
    window.addEventListener('scroll', handleScroll);
})
onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
})
</script>

<style lang="scss" scoped>
header {
    padding: 22px 0;
    position: relative;
    .header-wrap {
        padding: 10px;
        max-width: 1292px;
        width: 100%;
        transition: background .3s ease;
        border-radius: 24px;
        position: relative;
        &.fixed {
            position: fixed;
            top: 20px;
            left: 50%;
            transform: translateX(-50%);
            background: #272727;
            z-index: 4;
            width: calc(100% - 240px);
            max-width: 1272px;
        }
        & .nav__menu__item {
            &:hover {
                cursor: pointer;
                & > p, & > a p {
                    transition: color .2s ease;
                    color: #ea704a;
                }
                & svg, path {
                    transition: fill .2s ease;
                    fill: #ea704a;
                }
            }
        }
    }
    & .nav {
        gap: 50px;
        &__menu {
            gap: 56px;
        }
        .services, .solution {
            gap: 5px;
        }
        .services:hover {
            & .pop1 {
                transition: display .2s ease;
                display: block;
            }
        }
        .solution:hover {
            & .pop2 {
                transition: display .2s ease;
                display: block;
            }
        }
    }
}

.h-menu {
    font-size: 4vh; 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    overflow: hidden;
    &__blur {
        height: 100%;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 18.96%), url('../assets/images/bg-m.png'), lightgray 0% 0% / 124.57143068313599px 124.57143068313599px repeat, rgba(255, 255, 255, 0.01);
        backdrop-filter: blur(124.57142639160156px);
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
    }
    &__bg {
        border-radius: 100%;
        background: #EF8F00;
        height: 120vh;
        width: 867px;
        position: absolute;
        z-index: 4;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &__content {
        position: relative;
        z-index: 6;
        padding: 0.9em 0.9em; // 25px 20px;
        // font-size: 36px;
        font-weight: 600;
        line-height: 1em;
        height: 100%;
    }
    &__body {
        // padding: 30px 10px 0;
        // padding: 3.275vh 0;
        padding: 0.7em 0;
        height: 98%;
        & .icon-wrapper {
            width: 26px !important;
            height: 26px !important;
            margin-right: 16px;
        }
        & .h-title {
            // font-size: 1em;
            line-height: 1.2em;
        }
        & .h-title.active {
            color: #272727;
        }
        & .h-title + .h-title {
            // margin-top: 2.4vh;
            margin-top: 0.7em;
        }
        & .hidden-el.active p {
            color: #272727;
        }
        & .hidden-el:not(:nth-child(1)) {
            margin-top: 0.65em;
        }
        & .hidden-block {
            margin-top: 0.65em;
            display: none;
        }
        & .content-btm {
            margin: auto 0 0.7em;
        }
        & .btn {
            z-index: 6;
            margin-bottom: 0.8em;
            width: 100%;
        }
    }
    .small-item {
        font-size: 0.56em;
        font-weight: 700;
        line-height: 1.2em;
    }
    .bottom a {
        font-size: 14px;
        font-weight: 500;
        line-height: 34px;
        text-decoration-line: underline;
    }
}

@media (max-width: 1220px) {
    header {
        .header-wrap{
            width: calc(100% + 20px);
            transform: translateX(-10px);
        }
        .fixed {
            width: calc(100% - 180px) !important;
        }
    }
}

@media (max-width: 864px) {
    header {
        padding: 15px 0;
    }
    .nav {
        display: none;
    }
    .menu {
        display: flex;
        cursor: pointer;
    }
}

@media (max-width: 768px) {
    .icon-wrapper {
        width: 42px !important;
        height: 42px !important;
    }
    header {
        .fixed {
            width: calc(100% - 100px) !important;
        }
    }
}

@media (max-width: 425px) {
    header {
        .fixed {
            width: calc(100% - 40px) !important;
        }
    }
}
</style>